import * as React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import Seo from "../components/SEO"
import Button from "../components/Button/Button"
import { BlogSingleStyles } from "../components/Info/InfosStyles"


const about = () => {
  return (
    <>
      <Seo title="about" />
      <Layout>
         <BlogSingleStyles>
            <h1 className="blogsingle__title">За нас</h1>           
            <article className="blogsingle__content">            
              <p><strong>Вензел ЕООД</strong> е семейна фирма създадена през 2018г.  Произвеждаме артикули от пластмаса и дърво, като се стараем да  използваме най-новите технологии в света на 3д принтирането, лазерното  гравиране и рязане, както и CNC обработката на материали от дърво.  Полагаме усилия да обхванем целия процес от постъпването на суровината  до излизането на краен продукт, с цел по-добър контрол на качеството. </p>
              <p><strong>Вензел ЕООД</strong> уважава авторското право. Повечето  от продуктите, които ще намерите в нашия онлайн магазин са по наш дизайн и наистина са уникални! Поради същата причина сме способни да създадем и да Ви предложим персонални предложения, базирани на някой от вече  наличните в уебстраницата ни продукти, или да проектираме нещо специално за Вас.
              Като производител все още във фаза &quot;StartUp&quot;, сме изключително  отворени към работа с други фирми, които биха имали интерес от дейността ни. Имаме възможности да произвеждаме и по-големи количества от даден  артикул и то на преференциални цени. Ако сте заинтересовани, моля  свържете се с нас на имейл: <a href="mailto:venzelbgwork@gmail.com">venzelbgwork@gmail.com</a> или</p>
              използвайте формата ни за контакти.

              <div className="blogsingle__back">
              <Button text="Към начална страница" as={Link} to="/" />
              </div>
            </article>
          </BlogSingleStyles>
      </Layout>
    </>
  )
}

export default about
